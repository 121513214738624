  const getCookieValue = (key) => {
  let cookieKey = key + "="; 
  let result = "";
  const cookieArr = document.cookie.split(";");
  
  for(let i = 0; i < cookieArr.length; i++) {
    if(cookieArr[i][0] === " ") {
      cookieArr[i] = cookieArr[i].substring(1);
    }
    
    if(cookieArr[i].indexOf(cookieKey) === 0) {
      result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length);
      return result;
    }
  }
  return result;
 }

 const deleteCookie = (name) => {
   document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
 }

const getModal = () => {
  return (
    <>
    <div >
            주식회사 And Then Some에서 이용자의 정보를 안전하게 보호하고 관리하는 것은 우리의 최고가치 중 하나입니다. 세일즈로그 웹사이트에 방문하는 자 및 세일즈로그 서비스(세일즈로그 웹사이트를 포함하여 이하 ‘세일즈로그’) 이용자로부터 수집한 정보를 사용 및 보호하는 방법을 쉽게 이해할 수 있도록 본 개인정보 처리방침(이하 “개인정보 처리방침”)을 제정합니다.
            본 개인정보 처리방침은 여러분이 필요할 경우 전문을 읽을 수 있도록 항상 공개되어 있습니다. 개인정보 처리방침이 개정될 때는 여러분이 개정 세부 사항 및 개정 사유를 쉽게 알 수 있도록 공지하겠습니다.
            <br />
            <br />
            <p>[개인정보의 처리 목적]</p>
            And Then Some는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
            ① 웹사이트 회원가입 및 관리
            - 회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정 이용 방지, 각종 고지·통지, 고충 처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
            ② 재화 또는 서비스 제공
            - 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 나이 인증, 요금 결제·정산 등을 목적으로 개인정보를 처리합니다.
            ③ 마케팅 및 광고에의 활용
            - 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
            <br />
            <br />
            <p>[개인정보의 처리 및 보유 기간]</p>
            ① And Then Some는 법령에 따른 개인정보 보유·이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 이용 기간 내에서 개인정보를 처리, 보유합니다.
            ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            개인정보파일명
            운영 목적
            운영(수집) 근거
            운영(수집) 항목
            보유 기간
            회원정보
            And Then Some 서비스 이용
            정보주체의 동의
            이메일, 비밀번호, 이름, 결제정보
            회원탈퇴 시까지
            <br />
            <br />
            <p>개인정보의 제3자 제공에 관한 사항]</p>
            ① And Then Some는 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
            ② And Then Some는 정보주체의 개인정보를 처리 목적으로 명시한 범위를 초과하여 제3자에게 제공하지 않습니다. 다만, 다음 각호의 경우에는 제3자에게 제공할 수 있습니다.
            가. 정보주체로부터 별도의 동의를 받는 경우
            나. 다른 법률에 특별한 규정이 있는 경우
            다. 정보주체가 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전동의를 받을 수 없을 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
            라. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
            마. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 개인정보보호위원회의 심의･의결을 거친 경우
            바. 조약, 그 밖의 국제협정 이행을 위하여 외국 정부 또는 국제기구에 제공하는 데 필요한 경우
            사. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우
            아. 법원의 재판업무 수행을 위하여 필요한 경우
            자. 형(形) 및 감호, 보호처분의 집행을 위하여 필요한 경우
            <br />
            <br />
            

            And Then Some는 원칙적으로 개인정보 처리 목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
            ① 파기 절차
            - 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
            ② 파기 기한
            - 이용자의 개인정보는 개인정보의 보유 기간이 경과된 경우에는 보유 기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
            ③ 파기 방법
            가. 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
            나. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
            <br />
            <br />
            <p>      [개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항]</p>

            
            <br />
            <br />
            공포일: 2021. 10. 1
            <br />
            시행일: 2021. 11. 1
            <p style={{ marginBottom: 10 }}></p>
          </div>
    </>
   )
 }


export  { getCookieValue, deleteCookie, getModal };

