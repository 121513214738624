const words = [
  {
    text: '책임감',
    value: 77,
  },
  {
    text: '끈기',
    value: 77,
  },
  {
    text: '열정',
    value: 77,
  },
  {
    text: '불만없음',
    value: 77,
  },
  {
    text: '긍정적',
    value: 77,
  },
  {
    text: '낙천적',
    value: 70,
  },
  {
    text: '현실적',
    value: 77,
  },
  {
    text: '체력',
    value: 77,
  },
  {
    text: '건강',
    value: 77,
  },
  {
    text: '성실',
    value: 77,
  },
  {
    text: '부정적',
    value: 70,
  },
  {
    text: '배려',
    value: 77,
  },
  {
    text: '강요',
    value: 70,
  },
  {
    text: '존중',
    value: 77,
  },
  {
    text: '다혈질',
    value: 70,
  },
  {
    text: '자기조절',
    value: 77,
  },
  {
    text: '소통',
    value: 77,
  },
  {
    text: '게으름',
    value: 70,
  }
];

export default words;