import React from "react";
import MyCalendar from "../../components/calendar/MyCalendar";

const CalendarPage = () => {
  return (
    <>
      <MyCalendar />
    </>
  );
};

export default CalendarPage;
